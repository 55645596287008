<template>
  <div class="home">
    <HistogramController/>
  </div>
</template>

<script>
// @ is an alias to /src
import HistogramController from '@/components/HistogramController.vue'

export default {
  name: 'Home',
  components: {
    HistogramController
  }
}
</script>
