/* eslint-disable */

const MARKERS = 5;

export class Quantile2 {

  // Percentile to find
  p: number;

  // Last percentile value
  pValue: number = 0;
 
  // Initial observations
  initial: Array<number> = [];
  initialCount: number = 0;
  initialized: boolean = false;

  // Math stuff :shrugs:
  q: Array<number> = [];
  n: Array<number> = [];
  n_desired: Array<number> = [];
  dn: Array<number> = [];
  lastK: number = 0;

  constructor(p: number) {
    // Set percentile
    this.p = p;
  }

  init() {
    // Set initialized flag
    this.initialized = true;

    // Process initial observations
    for (let i = 0; i < MARKERS; ++i) {
      // Set initial marker heights
      this.q[i] = this.initial[i];

      // Initial marker positions
      this.n[i] = i;
    }

    // Desired marker positions
    this.n_desired[0] = 0;
    this.n_desired[1] = 2 * this.p;
    this.n_desired[2] = 4 * this.p;
    this.n_desired[3] = 2 + 2 * this.p;
    this.n_desired[4] = 4;

    // Precalculated desired marker increments
    this.dn[0] = 0;
    this.dn[1] = this.p / 2.0;
    this.dn[2] = this.p;
    this.dn[3] = (1 + this.p) / 2.0;
    this.dn[4] = 1;
  }

  acceptInitial(x:number): boolean {
    if (this.initialCount < MARKERS) {
      this.initial[this.initialCount++] = x;
  
      // Arrays.sort(initial, 0, this.initialCount);

      debugger;

      this.initial.sort((a, b) => a - b);
      return false;
    }

    // Enough values available
    debugger;
    this.initial.sort((a, b) => a - b);
    this.init();
    return true;
  }




  initialSetPercentile(): number {
    const n: number = Math.floor(this.p * this.initialCount);
    return this.initial[n];
  }

  accept(x: number): number {
    // Still recording initial values
    if (!this.initialized) {
      if (!this.acceptInitial(x)) {
        this.pValue = this.initialSetPercentile();
        return this.pValue;
      }
    }

    let k: number = -1;
    if (x < this.q[0]) {
      // Update minimum value
      this.q[0] = x;
      k = 0;
    } else if (this.q[0] <= x && x < this.q[1])
      k = 0;
    else if (this.q[1] <= x && x < this.q[2])
      k = 1;
    else if (this.q[2] <= x && x < this.q[3])
      k = 2;
    else if (this.q[3] <= x && x <= this.q[4])
      k = 3;
    else if (this.q[4] < x) {
      // Update maximum value
      this.q[4] = x;
      k = 3;
    }

    // Check if k is set properly
    if (k < 0) {
      throw new Error('something bad happened :(');
    }

    this.lastK = k;

    // Increment all positions starting at marker k+1
    for (let i = k + 1; i < MARKERS; i++) {
      this.n[i]++;
    }

    // Update desired marker positions
    for (let i = 0; i < MARKERS; i++) {
      this.n_desired[i] += this.dn[i];
    }

    // Adjust marker heights 2-4 if necessary
    for (let i = 1; i < MARKERS - 1; i++) {
      const d: number = this.n_desired[i] - this.n[i];

      if ((d >= 1 && (this.n[i + 1] - this.n[i]) > 1) || (d <= -1 && (this.n[i - 1] - this.n[i]) < -1)) {
        const ds: number = this.sign(d);

        // Try adjusting q using P-squared formula
        const tmp: number = this.parabolic(ds, i);
        if (this.q[i - 1] < tmp && tmp < this.q[i + 1]) {
          this.q[i] = tmp;
        } else {
          this.q[i] = this.linear(ds, i);
        }

        this.n[i] += ds;
      }
    }

    // Set current percentile value for later retrieval
    this.pValue = this.q[2];
    return this.q[2];
  }

  getPValue(): number {
    return this.pValue;
  }

  linear(d: number, i: number): number {
    return this.q[i] + d * (this.q[i + d] - this.q[i]) / (this.n[i + d] - this.n[i]);
  }

  parabolic(d: number, i: number): number {
    const a: number =  d /  (this.n[i + 1] - this.n[i - 1]);

    const b: number =  (this.n[i] - this.n[i - 1] + d) * (this.q[i + 1] - this.q[i]) /  (this.n[i + 1] - this.n[i])
        +  (this.n[i + 1] - this.n[i] - d) * (this.q[i] - this.q[i - 1]) /  (this.n[i] - this.n[i - 1]);

    return  this.q[i] + a * b;
  } 

  sign(d: number): number {
    if (d >= 0)
      return 1;

    return -1;
  }
}

