<template>
  <div>
    <div class="header border-bottom">
      <h1 class="h2 m-auto pt-3 pb-2">Histogram Percentile Estimation Tuning Tool</h1>
      <p class="h5 font-weight-light pb-2">Explore histogram binning strategies to find the optimal set of bins for estimating percentiles in your latency SLIs and SLOs</p>
    </div>

    <div class="d-flex p-5">
      <div class="d-flex flex-column justify-space-between border-right mr-5 pr-5" style="width: 300px;">
        <h3 class="h4 border-bottom pb-2 mb-3">Instructions</h3>

        <ul class="text-left">
          <li>
            Percentiles are a common measure of service-level indicators
          </li>
          <li>
            Percentiles are expensive to compute exactly, so estimates are used instead
          </li>
          <li>
            Metrics can be published as Histograms, and Argus can use Histograms to estimate percentiles.
          </li>
          <li>
            The accuracy of percentile estimates from histograms is a function of the number and spacing of bins.
          </li>
          <li>
            This tool will help you test datasets against histogram binning strategies to understand what percentile values are possible.
          </li>
        </ul>
      </div>

      <div class="d-flex flex-column justify-space-between">

        <h3 class="h4 border-bottom pb-2 mb-3">Enter Dataset</h3>
        <b-form-group
          label=""
          label-for="textarea-formatter"
          description="Enter data one line at a time"
          class="mb-0 flex-grow-1">
          <b-form-textarea
            id="textarea-formatter"
            v-model="dataPointsText"
            placeholder=""
            rows="18"
          ></b-form-textarea>
        </b-form-group>
        <b-button 
          @click="updateHistogram"
          style="width: fit-content; margin:auto" variant="primary">
            Update Bins
        </b-button>

      </div>
      <div class="flex-grow-1">
        <h3 class="h4 border-bottom pb-2 mb-3">Histogram With {{selectedText}}</h3>

        <Histogram 
          :binnedData="binnedData"
          class="w-100">
        </Histogram>
      </div>
      <div class="d-flex flex-column justify-content-between pl-5 pr-5">
        <h3 class="h4 border-bottom pb-2">Set Histogram Bins</h3>

        <div class="pb-4">
          <h4 class="h5">Select A Binning Strategy</h4>
          <div class="d-flex flex-column">
            <b-form-select v-model="selected" :options="binOptions"></b-form-select>
            <!-- <div class="mt-3">Selected Bins: <strong>{{ selectedText }}</strong></div> -->
          </div>
        </div>

        <h4 class="h5">Or, Define Your Own Bins</h4>

        <div class="d-flex">
          <b-form-group
            label=""
            label-for="textarea-formatter"
            description="Enter upper bound for each bin one line at a time"
            class="mb-0 flex-grow-1"
          >
            <b-form-textarea
              id="textarea-formatter"
              ref="binsInput"
              v-model="binsText"
              placeholder=""
              rows="15"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <b-button 
          @click="updateHistogram"
          style="width: fit-content; margin:auto" variant="primary">
            Update Bins
        </b-button>
      </div>
    </div>
    <div class="pt-5 border-top d-flex flex-column justify-content-around">

      <div class="d-flex flex-row justify-content-around">
        <div style="width: 31%" class="">
          <h3 class="m-auto pb-4 h4">Possible Percentile Values</h3>
          <div class="h5 font-weight-light text-left">
            <p>Your histogram defines <strong class="font-weight-bold">{{bins.length}}</strong> possible values for percentile estimates</p>
            <p class="font-italic">This means when you're looking at the p5, p25 p50, p95, p99, etc of this dataset, it will always be one of the following 4 values:</p>
            <p><strong class="font-weight-bold">{{possiblePercentileValues.map(p => p + ' ms').join(', ')}}</strong></p>
          </div>

          <!--
          <div>
            <ul class="text-left">
              <li  v-for="value in possiblePercentileValues" :key="value">
                {{value}} ms
              </li>
            </ul>
          </div>
          -->
        </div>
        <div class="">
          <h3 class="m-auto pb-4 h4">{{selectedText}} - Percentile Estimates For Dataset</h3>
          <p class="h5 font-weight-light pb-2">Given the dataset and histogram, what are the estimated <strong class="font-weight-bold">{{percentiles.join(", ")}}</strong> pvalues you'd see in Argus?</p>

          <b-table striped hover :items="estimatedPercentiles" :fields="fields"></b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Histogram from '@/components/Histogram';

const BIN_OPTIONS = [
{
  value: "500-1000-2000-4000",
  text: "4 Irregular Bins (500 | 1000 | 2000 | 4000)"
}, {
  value: "1000-2000-3000-4000",
  text: "4 Regular Bins (1000 | 2000 | 3000 | 4000)"
}, {
  value: "500-1000-1500-2000-2500-3000",
  text: "6 Regular Bins (500 | 1000 | 1500 | 2000 | 2500 | 3000)"
}, {
  value: "100-200-300-400-500-600-700-800-900-1000-1100-1200-1300-1400-1500-1600-1700-1800-1900-2000-2100-2200-2300-2400-2500-2600-2700-2800-2900-3000",
  text: "30 Regular Bins (100 | 200 | 300 | ... | 3000)"
}, {
  value: "50-100-150-200-250-300-350-400-450-500-550-600-650-700-750-800-850-900-950-1000-1050-1100-1150-1200-1250-1300-1350-1400-1450-1500-1550-1600-1650-1700-1750-1800-1850-1900-1950-2000-2050-2100-2150-2200-2250-2300-2350-2400-2450-2500-2550-2600-2650-2700-2750-2800-2850-2900-2950-3000",
  text: "60 Regular Bins (50 | 100 | 150 | 200 | ... | 3000)"
}
];

const PERCENTILES = [0.25, 0.50, 0.75, 0.95, 0.99, 0.999];

const FIELDS = [{key: "name", label: "Number of Bins", sortable: false}].concat(PERCENTILES.map(p => { return { key: "" + p, sortable: false }; } ));

export default {

  name: 'HelloWorld',
  components: {
    Histogram,
  },

  data() {

    const bins        = [500, 1000, 2000, 4000];
    const dataPoints  = [2868, 2449, 1373, 1412, 1003, 945, 1831, 1493, 2399, 2534, 1433, 1352, 1783, 1395, 1622, 1554, 1511, 1000, 1142, 621, 874, 490, 971, 962, 755, 458, 1476, 1271, 1006, 1535, 118, 654, 1287, 1350, 926, 769, 863, 1669, 1464, 469, 449, 1161, 1213, 1510, 1057, 820, 1237, 1942, 1859, 1551, 1678, 1427, 1930, 1620, 954, 1640, 477, 1161, 2149, 1349, 1392, 2435, 1064, 1319, 1544, 1008, 1577, 854, 1369, 1781, 989, 712, 902, 1689, 1456, 2121, 886, 1923, 1693, 1330, 760, 1729, 666, 1106, 918, 1354, 1475, 820, 573, 2248, 924, 1301, 1413, 1249, 1375, 952, 737];
    // const dataPoints  = [20, 15, 50, 40, 35];
 
    return {
      dataPoints,
      bins,
      binsText: bins.join('\n'),
      dataPointsText: dataPoints.join('\n'),
      binOptions: BIN_OPTIONS,
      selected: BIN_OPTIONS[0].value,
      percentiles: PERCENTILES,
      fields: FIELDS
    }
  },

  props: {
    msg: String
  },

  computed: {

    binnedData() {

      const binnedData = [];

      for (let i = 0; i < this.bins.length; ++i) {

        // Establish upper and lower bound
        const binUpperBound = this.bins[i];
        const binLowerBound = i === 0 ? 0 : this.bins[i - 1];

        // Count the data points in this bin
        const count = this.dataPoints.filter(dataPoint => {
          return dataPoint <= binUpperBound && dataPoint > binLowerBound
        }).length;

        // Add an entry
        binnedData.push({
          upperBound: binUpperBound,
          count
        });
      }
      binnedData.unshift({ upperBound: 0, count: 0 });
      return binnedData;
    },

    estimatedPercentiles() {

      // Histogram estimates
      const result = {};
      result['name'] = this.selected.split('-').length;
      PERCENTILES.forEach(percentile => {
        result[percentile] = this.computePercentile(percentile);
      });

      // Real values
      const realPercentiles = {};
      realPercentiles['name'] = "Actual Percentiles";
      PERCENTILES.forEach(percentile => {
        // realPercentiles[percentile] = this.computePercentileInterpolation(percentile);
        realPercentiles[percentile] = this.computedPercentileINC(percentile);
      });

      return [result, realPercentiles];
    },

    possiblePercentileValues() {
      const result = [];

      for (let i = 0; i < this.bins.length; ++i) {
        let lowerBound = this.bins[i - 1] || 0;
        let upperBound = this.bins[i];

        result.push((upperBound + lowerBound) / 2);
      }

      return result;
    },

    selectedText() {
      const selectedOption = this.binOptions.find(option => option.value === this.selected)
      if (selectedOption) {
        return selectedOption.text;
      }
      return ''; 
    }
  },

  watch: {
    selected() {
      // update bins
      this.bins = this.selected.split('-').map(bin => parseInt(bin));
      this.binsText = this.bins.join('\n');
    }
  },

  methods: {

    updateHistogram() {
      const newBins = this.binsText.split('\n').map(bin => parseInt(bin));
      // const dataPointsInputValue = this.$refs["dataPointsInput"].value;
      this.bins = newBins;

      const newData = this.dataPointsText.split('\n').map(dataPoint => parseInt(dataPoint));
      this.dataPoints = newData;
    },

    computePercentile(pValue) {

      // TODO: does this actually work?
      const totalCount = this.binnedData.map(el => el.count).reduce(
        function(accumulator, currentValue, currentIndex, array) {
          return accumulator + currentValue
        }
      );

      let runningCount = 0;
      for (let i = 0; i < this.binnedData.length; ++i) {
        const bin = this.binnedData[i];
        runningCount += bin.count;

        // Figure out which bin the pValue would be in 
        if (runningCount / totalCount >= pValue) {
          // Found it

          // Percentile defined as the midpoint of this bucket
          const binUpperBound = bin.upperBound;

          // Lower bound is the previous bin's upper bound + 1, or zero 
          // if there is no previous bin
          const binLowerBound = i === 0 ? 0 : (this.binnedData[i - 1].upperBound + 1);

          const midpoint = (binUpperBound + binLowerBound) / 2

          return Math.floor(midpoint) + ' ms';
        }
      }

      throw new Error("Oh noes! Something bad happpened")
    },

    // https://en.wikipedia.org/wiki/Percentile#The_linear_interpolation_between_closest_ranks_method
    computePercentileInterpolation(pValue) {
      
      // if (pValue === 0.95 || pValue === 0.4) {
      //   debugger;
      // }

      // 0. sort the list
      const sortedDataPoints = this.dataPoints;
      sortedDataPoints.sort((a, b) => a - b);

      // 1. calculate rank
      // x={\frac {40}{100}}(5+1)=2.4

      const rank = (pValue) * (sortedDataPoints.length + 1) - 1;

      const isInt = rank === Math.floor(rank);

      if (isInt) {
        return sortedDataPoints[rank];
      }

      const lowerValue = sortedDataPoints[Math.floor(rank)];
      const upperValue = sortedDataPoints[Math.ceil(rank)];

      const remainder = rank % 1;

      const interpolatedValue = lowerValue + (remainder * (upperValue - lowerValue))
      return interpolatedValue;
    },

    computedPercentileINC(pValue) {
      //       First we calculate the rank of the 40th percentile:

      // {\displaystyle x={\frac {40}{100}}(5-1)+1=2.6}x={\frac {40}{100}}(5-1)+1=2.6
      // So, x=2.6, which gives us {\displaystyle \lfloor x\rfloor =2}\lfloor x\rfloor =2 and {\displaystyle x\%1=0.6}{\displaystyle x\%1=0.6}. So, the value of the 40th percentile is

      // {\displaystyle v(2.6)=v_{2}+0.6(v_{3}-v_{2})=20+0.6(35-20)=29.}v(2.6)=v_{2}+0.6(v_{3}-v_{2})=20+0.6(35-20)=29.

      // if (pValue === .4) {
      //   debugger
      // }

      // 0. sort the list
      const sortedDataPoints = this.dataPoints;
      sortedDataPoints.sort((a, b) => a - b);

      const rank = (pValue) * (sortedDataPoints.length - 1);

      // const floor = Math.floor(rank);
      const remainder = rank % 1;

      const lowerValue = sortedDataPoints[Math.floor(rank)];
      const upperValue = sortedDataPoints[Math.ceil(rank)];

      return lowerValue + remainder * (upperValue - lowerValue);


    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// h3 {
//   margin: 40px 0 0;
// }
// ul {
//   list-style-type: none;
//   padding: 0;
// }
// li {
//   display: inline-block;
//   margin: 0 10px;
// }
// a {
//   color: #42b983;
// }

</style>
