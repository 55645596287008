<template>
  <div class="foo">
  </div>
</template>

<script>
/* eslint-disable */
import * as d3 from 'd3';

let originalWidth;
let originalHeight;

export default {

  /**
   * Adapted from d3 irregular histogram POC: http://bl.ocks.org/mbostock/raw/1624660/ 
   */
  name: 'Histogram',

  props: {
    binnedData: Array
  },

  mounted() {
    this.drawHistogram();
  },

  methods: {
    drawHistogram() {
        // Each line -> 'bin'
      const bins = this.binnedData;

    var margin = {top: 30, right: 60, bottom: 70, left: 70};

    if (!originalWidth && !originalHeight) {
      originalWidth = this.$el.getBoundingClientRect().width - margin.left - margin.right;
      originalHeight = this.$el.getBoundingClientRect().height - margin.top - margin.bottom;
    }

    var width = originalWidth;
    var height = originalHeight;
        // width = 960 - margin.left - margin.right,
        // height = 500 - margin.top - margin.bottom;

    this.$el.innerHTML = "";

    var svg = d3.select(".foo").append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("padding", 10)

      .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var x = d3.scale.linear()
      .range([0, width]);

    var y = d3.scale.linear()
      .range([height, 0]);


      // Coerce types.
      // this.binnedDatabins.forEach(function(bin) {
      //   bin.Income = +bin.Income;
      //   bin.People = +bin.People;
      // });

      // Normalize each bin to so that height = quantity/width;
      // see http://en.wikipedia.org/wiki/Histogram#Examples
      for (var i = 1, n = bins.length, bin; i < n; i++) {
        bin = bins[i];
        bin.offset = bins[i - 1].upperBound;
        bin.width = bin.upperBound - bin.offset;
        // bin.height = bin.count / bin.width;
        bin.height = bin.count;
        bin.color = `#${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`
      }

      // Drop the first bin, since it's incorporated into the next.
      bins.shift();

      // Set the scale domain.
      x.domain([0, d3.max(bins.map(function(d) { return d.offset + d.width; }))]);
      y.domain([0, d3.max(bins.map(function(d) { return d.height; }))]);

      // Add the bins.
      svg.selectAll(".bin")
          .data(bins)
        .enter().append("rect")
          .attr("class", "bin")
          .attr("x", function(d) { return x(d.offset); })
          .attr("width", function(d) { return x(d.width) - 1; })
          .attr("fill", function(d) { return d.color })
          .attr("y", function(d) { return y(d.height); })
          .attr("height", function(d) { return height - y(d.height); });

      let xAxisEl = svg.append("g");
      xAxisEl
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
          .text("label")
          .call(d3.svg.axis()
          .scale(x)
          .orient("bottom"))
        .select('path')
          .style({ 'stroke': 'black', 'fill': 'none', 'stroke-width': '2px'});

      xAxisEl
          .append("text")
          .attr("fill", "black")//set the fill here
          .attr("transform",`translate(${width / 2}, 50)`)
          .text("Response Time (ms)");;   


      let yAxisEl = svg.append("g");
      yAxisEl.attr("class", "y axis")
          .call(d3.svg.axis()
          .scale(y)
          .orient("left"))
        .select('path')
          .style({ 'stroke': 'black', 'fill': 'none', 'stroke-width': '2px'});

      yAxisEl
          .append("text")
          .attr("fill", "black")//set the fill here
          .attr("transform",`translate(-70, ${height / 2})`)
          .attr("transform",`rotate(90deg)`)
          .text("Number of Requests");;   
    }

  },

  watch: {
    binnedData() {
      this.drawHistogram();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.foo {
  overflow: visible;
  margin: 15px;
  width: 100%;
  height: 100%;
}
</style>
