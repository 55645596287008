<template>
  <div class="about">
    <Percentile/>
  </div>
</template>

<script>
/* eslint-disable */

import Percentile from '@/components/Percentile'

export default {
  name: 'About',
  components: {
    Percentile
  }
}
</script>
