// TODO: try again using https://github.com/jacksonicson/psquared/blob/master/src/de/tum/in/dss/psquare/PSquared.java

// OR... fuck it why not use the SFDC one? duh

// P-square maitains five markers that store points.
const nMarkers = 5

// // Quantile represents an estimated p-quantile of a stream of observations.
// type Quantile struct {
// 	p      float64
// 	filled bool

// 	// marker positions, 1..nMarkers
// 	pos [nMarkers]int
// 	// desired marker positions
// 	npos [nMarkers]float64
// 	// increament in desired marker positions
// 	dn [nMarkers]float64
// 	// marker heights that store observations
// 	heights []float64
// }

// Hypothesis:
// - minimum number of observations === number of markers? 

// https://www.cse.wustl.edu/~jain/papers/ftp/psqr.pdf

/* eslint-disable */

export class Quantile {
  pValue: any;
  filled: boolean;
  heights: any[];
  pos: any[];
  npos: any[];
  dn: any[];

  constructor(pValue: number) {
    if (pValue < 0 || pValue > 1) {
      throw new Error("p-quantile is out of range");
    }

    this.pValue = pValue;
    this.filled = false

    this.heights = [0, 0, 0, 0, 0];
    this.pos = [];
    this.npos = [];
    this.dn = [];

    for (let i = 0; i < nMarkers; ++i) {
      // this.heights[i] = 0.0;
      this.pos[i] = i;
    }

    this.npos = [
      0.0,
      2 * this.pValue,
      4 * this.pValue,
      2 + (2 * this.pValue),
      4,
    ];

    this.dn = [
      0.0,
      this.pValue / 2,
      this.pValue,
      (1 + this.pValue) / 2,
      1,
    ];
  }


  append(value: number) {

    // TODO: problem is this never gets hit... why? how is this.heights to be init'd?
    if (this.heights.length !== nMarkers) {

      // no required number of observations has been appended yet
      // this.heights = this.appendHelper(value);
      this.appendHelper(value);
      return;
    }
    if (!this.filled) {
      this.filled = true;

      // TODO: test this
      this.heights.sort((a, b) => a - b);
    }
    this.appendHelper(value)
  }

  appendHelper(value: number) {

    debugger;

    let l = this.heights.length - 1;
    let k = -1;

    if (value < this.heights[0]) {
      k = 0;
      this.heights[0] = value;
    } else if (this.heights[l] <= value) {
      k = l - 1;
      this.heights[l] = value;
    } else {
      for (let i = 1; i <= l; i++) {
        if (this.heights[i-1] <= value && value < this.heights[i]) {
          k = i - 1
          break;
        }
      }
    }
  
    for (let i = 0; i < this.pos.length; ++i) {
      // increment positions greater than k
      if (i > k) {
        this.pos[i]++
      }
      // update desired positions for all markers
      this.npos[i] += this.dn[i]
    }
  
    this.adjustHeights();

    // k := -1
    // if v < q.heights[0] {
    //   k = 0
    //   q.heights[0] = v
    // } else if q.heights[l] <= v {
    //   k = l - 1
    //   q.heights[l] = v
    // } else {
    //   for i := 1; i <= l; i++ {
    //     if q.heights[i-1] <= v && v < q.heights[i] {
    //       k = i - 1
    //       break
    //     }
    //   }
    // }
  
    // for i := 0; i < len(q.pos); i++ {
    //   // increment positions greater than k
    //   if i > k {
    //     q.pos[i]++
    //   }
    //   // update desired positions for all markers
    //   q.npos[i] += q.dn[i]
    // }
  
    // q.adjustHeights()
  }


  adjustHeights() {

    debugger;

    for (let i = 1; i < this.heights.length - 1; ++i) {
      let n = this.pos[i]
      let np1 = this.pos[i+1]
      let nm1 = this.pos[i-1]

      let d = this.npos[i] - (n * 1.0);

      if ((d >= 1 && np1-n > 1) || (d <= -1 && nm1-n < -1)) {
        if (d >= 0) {
          d = 1
        } else {
          d = -1
        }

        let h = this.heights[i]
        let hp1 = this.heights[i+1]
        let hm1 = this.heights[i-1]

        // try adjusting height using P-square formula
        // hi = parabolic(d, hp1, h, hm1, (np1 * 1.0), (n * 1.0), (nm1 * 1.0));
        let hi = this.parabolic(d * 1.0, hp1 * 1.0, h * 1.0, hm1 * 1.0, np1 * 1.0, n * 1.0, nm1 * 1.0);

        if (hm1 < hi && hi < hp1) {

          console.log('using parabolic');

          this.heights[i] = hi
        } else {

          console.log('using linear');

          // use linear formula
          let hd = this.heights[i + Math.floor(d)]
          let nd = this.pos[i + Math.floor(d)]
          this.heights[i] = h + d*(hd-h)/((nd-n) * 1.0)
        }

        this.pos[i] += Math.floor(d)
      }
    }
  }

  parabolic(d: number, qp1: number, q: number, qm1: number, np1: number, n: number, nm1: number) {
      let a = d / (np1 - nm1)
      let b1 = (n - nm1 + d) * (qp1 - q) / (np1 - n)
      let b2 = (np1 - n - d) * (q - qm1) / (n - nm1)
      return q + a * (b1 + b2)
  }

  value() {

    // NOTE: test this!!!
    if (!this.filled) {

      console.log('!this.filled fast path')
      
      // a fast path when not enought observations has been stored yet
      let l = this.heights.length;
      switch (l) {
      case 0:
        return 0
      case 1:
        return this.heights[0]
      }
      this.heights.sort((a, b) => a - b);
      let rank = Math.floor(this.pValue * (l))
      return this.heights[rank]
    }

    // if initialised with nMarkers observations third height stores current
    // estimate of p-quantile
    // return this.heights[2]
    return this.heights[2]
  }
}
