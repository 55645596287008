<template>
  <div>
    percentiles yo
  </div>
</template>

<script>
/* eslint-disable */

import { Quantile } from '../pSquare'
import { Quantile2 } from '../pSquare2'

// const dataSet = [2000, 1500, 1900, 3000, 950, 2800, 2000, 1500, 1900, 3000, 950, 2800, 2000, 1500, 1900, 3000, 950, 2800, 2000, 1500, 1900, 3000, 950, 2800];
// const dataSet = [1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34, 1, 11, 21, 35, 80, 90, 100, 34];

const dataSet = [100, 300, 500, 700, 900, 100, 300, 500, 700, 900, 100, 300, 500, 700, 900, 100, 300, 500, 700, 900, 100, 300, 500, 700, 900, 100, 300, 500, 700, 900,100, 300, 500, 700, 900, 100, 300, 500, 700, 900, 100, 300, 500, 700, 900,  ];

export default {

  name: 'Percentile',

  mounted() {

    window.quantile_99 = new Quantile(.99)
    dataSet.forEach(datum => quantile_99.append(datum));

    window.quantile_95 = new Quantile(.95)
    dataSet.forEach(datum => quantile_95.append(datum));

    window.quantile_75 = new Quantile(.75)
    dataSet.forEach(datum => quantile_75.append(datum));

    window.quantile_50 = new Quantile(.50)
    dataSet.forEach(datum => quantile_50.append(datum));

    window.quantile2_99 = new Quantile2(.99)
    dataSet.forEach(datum => quantile2_99.accept(datum));

    window.quantile2_95 = new Quantile2(.95)
    dataSet.forEach(datum => quantile2_95.accept(datum));

    window.quantile2_75 = new Quantile2(.75)
    dataSet.forEach(datum => quantile2_75.accept(datum));

    window.quantile2_50 = new Quantile2(.50)
    dataSet.forEach(datum => quantile2_50.accept(datum));


    console.log('99% quantile 1: ' + quantile_99.value());
    console.log('95% quantile 1: ' + quantile_95.value());
    console.log('75% quantile 1: ' + quantile_75.value());
    console.log('50% quantile 1: ' + quantile_50.value());

    console.log('99% quantile 2: ' + quantile2_99.getPValue());
    console.log('95% quantile 2: ' + quantile2_95.getPValue());
    console.log('75% quantile 2: ' + quantile2_75.getPValue());
    console.log('50% quantile 2: ' + quantile2_50.getPValue());

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.foo {
  width: 400px;
}
</style>
